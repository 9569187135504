import React from "react"

// components
import Layout from "@modules/layout"
import Seo from "@modules/seo"
import Grundhaltung from "@modules/grundhaltung"

const IndexPage = ({ location }: any) => (
  <Layout location={location}>
    <Seo title="Grundhaltung und Werte">
      <Grundhaltung />
    </Seo>
  </Layout>
)

export default IndexPage
