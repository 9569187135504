import styled from "styled-components"
import breakpoints from "@configs/breakpoints"

export const Recommandation = styled.section`
  display: contents;

  h3 {
    grid-column: 1 / -1;
  }

  & > div {
    grid-column: span 3;
  }

  @media screen and (max-width: ${breakpoints["1200"]}) {
    div {
      grid-column: span 6;
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    div {
      grid-column: span 4;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    div {
      grid-column: 1 / -1;
    }
  }
`
