import styled from "styled-components"
import breakpoints from "../../configs/breakpoints"

export const Container = styled.div`
  display: contents;

  h1 {
    grid-column: 1 / -1;
  }

  h2 {
    grid-column: span 6;
  }

  section {
    grid-column: 1 / -1;
  }

  @media screen and (max-width: ${breakpoints[1200]}) {
    h2 {
      grid-column: 1 / -1;
    }
  }
  @media screen and (max-width: ${breakpoints.mobile}) {
    h2 {
      grid-column: 1 / -1;
      margin-top: var(--gap-13);
      font-size: 1em !important;
    }
  }
`

export const Intro = styled.section`
  margin-top: var(--gap-50);
  b {
    font-weight: bold;
  }

  ul > li {
    display: flex;
    align-items: center;
    margin-bottom: var(--gap-107);

    .img {
      min-width: 230px;
      max-width: 230px;
      margin-right: var(--gap-32);
    }

    h3 {
      margin-bottom: var(--gap-18);
    }

    @media screen and (max-width: ${breakpoints.tablet}) {
      flex-direction: column;
      margin-bottom: var(--gap-68);
      .img {
        margin-right: 0;
        margin-bottom: var(--gap-18);
      }
    }
  }
`

export const Werte = styled.section`
  display: contents;

  h3 {
    grid-column: 1/ -1;
    font-size: 3em;
    margin-bottom: var(--gap-32);
  }

  ul {
    display: contents;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: var(--clr-green);
      padding: var(--gap-50);
      grid-column: span 4;
      margin-bottom: var(--gap-165);

      & :first-child {
        font-weight: bold;
        font-size: 1.45em;
        text-align: center;
        margin-bottom: var(--gap-13);
      }

      @media screen and (max-width: ${breakpoints[1200]}) {
        grid-column: 1 / -1;
        margin-bottom: var(--gap-13);

        &:last-child {
          margin-bottom: var(--gap-165);
        }
      }
    }
  }
`
