import React from "react"
import { getImage } from "gatsby-plugin-image"
import ImgLink from "../common/imgLink"

import * as Styled from "./Recommandation.styles"
import createKey from "@services/key"
import { slugs } from "../../configs/slugs"
import { graphql, useStaticQuery } from "gatsby"

interface IData {
  coaching: boolean
  organisationsentwicklung: boolean
  seminare_und_workshops: boolean
  outdoor: boolean
}

interface Props {
  data: IData
  title: string
}

const query = graphql`
  query {
    coaching: file(relativePath: { regex: "/coaching.jpg/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    organisationsentwicklung: file(
      relativePath: { regex: "/organisationsentwicklung.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    outdoortraining: file(relativePath: { regex: "/outdoortraining.jpg/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    seminare_und_workshops: file(
      relativePath: { regex: "/seminare_und_workshops.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
  }
`

const Recommandation = ({ title, data }: Props) => {
  const {
    coaching,
    organisationsentwicklung,
    seminare_und_workshops,
    outdoortraining,
  } = useStaticQuery(query)

  const coachingImg: any = getImage(coaching)
  const organisationsentwicklungImg: any = getImage(organisationsentwicklung)
  const seminareImg: any = getImage(seminare_und_workshops)
  const outdoortrainingImg: any = getImage(outdoortraining)

  return (
    <Styled.Recommandation>
      <h3>{title}</h3>
      {data.organisationsentwicklung && (
        <ImgLink
          href={slugs.leistungen["organisationsentwicklung"]}
          alt={"test"}
          image={organisationsentwicklungImg}
          buttonText={"Organisationsentwicklung"}
          key={createKey()}
        />
      )}
      {data.coaching && (
        <ImgLink
          href={slugs.leistungen["coaching"]}
          alt={"test"}
          image={coachingImg}
          buttonText={"Coaching"}
          key={createKey()}
        />
      )}
      {data.seminare_und_workshops && (
        <ImgLink
          href={slugs.leistungen["seminare-und-workshops"]}
          alt={"test"}
          image={seminareImg}
          buttonText={"Seminare & Workshops"}
          key={createKey()}
        />
      )}
      {data.outdoor && (
        <ImgLink
          href={slugs.leistungen["outdoor-training"]}
          alt={"test"}
          image={outdoortrainingImg}
          buttonText={"Outdoor-Training"}
          key={createKey()}
        />
      )}
    </Styled.Recommandation>
  )
}

export default Recommandation
