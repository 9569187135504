import styled from "styled-components"
import breakpoints from "@configs/breakpoints"

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  margin: var(--gap-27) 0;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--clr-green);
  padding: 0 var(--gap-18);

  a {
    margin-bottom: var(--gap-27);
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    ${ImgContainer} {
      min-width: 96px;
      min-height: 96px;
      max-width: 96px;
      max-height: 96px;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    ${ImgContainer} {
      min-width: 200px;
      min-height: 200px;
      max-width: 200px;
      max-height: 200px;
    }
  }
`
