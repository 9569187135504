import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Recommandation from "@modules/recommandation"

import { slugs } from "@configs/slugs"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as Styled from "./Grundhaltung.styles"

const query = graphql`
  query {
    arbeitsweisen_icon: file(relativePath: { regex: "/arbeitsweisen_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    vorgehen_icon: file(relativePath: { regex: "/vorgehen_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    ziel_icon: file(relativePath: { regex: "/ziel_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
  }
`

const Grundhaltung = () => {
  const { arbeitsweisen_icon, vorgehen_icon, ziel_icon } = useStaticQuery(query)
  const arbeitsweisen: any = getImage(arbeitsweisen_icon)
  const vorgehen: any = getImage(vorgehen_icon)
  const ziel: any = getImage(ziel_icon)
  return (
    <Styled.Container>
      <h1>Unsere Grundhaltung & unsere Arbeitsweise</h1>
      <h2>
        Wir arbeiten achtsamkeitsbasiert und auf Grundlage der Erkenntnisse der
        modernen Gehirnforschung.
      </h2>
      <Styled.Intro>
        <ul>
          <li>
            <div className="img">
              <GatsbyImage
                alt="test"
                image={arbeitsweisen}
                objectFit="contain"
              />
            </div>
            <div>
              <h3>Unser Ziel</h3>
              <p>
                Unser Ziel ist es, die Denk- und Handlungsmuster von Menschen
                und Organisationen bewusst zu machen und zu reflektieren. Unsere
                Tätigkeitsfelder sind: die Organisationsentwicklung, das
                Führungskräfte-Training sowie das Coaching. Wir wollen
                Führungskräfte und Mitarbeiter motivieren, die eigenen
                Fähigkeiten zu entwickeln, Selbstreflexionen in Gang zu setzen
                und vermitteln, wie die neu erworbenen Fähigkeiten aktiv genutzt
                werden können. Wir möchten Sie dazu inspirien, souverän, achtsam
                und pragmatisch miteinander zuarbeiten!
              </p>
            </div>
          </li>
          <li>
            <div className="img">
              <GatsbyImage alt="test" image={vorgehen} objectFit="contain" />
            </div>
            <div>
              <h3>Unser Vorgehen</h3>
              <p>
                Unsere Herausforderung sehen wir darin, aktuelle
                wissenschaftliche Erkenntnisse durch praxisorientierte,
                individuelle Lösungsansätze für Sie nutzbar zu machen. Unsere
                Ausrichtung orientiert sich am hypnosystemischen Denken und
                Handeln.
              </p>
              <br />
              <p>
                <b>Schritt 1: Innehalten/Erkenntnis:</b> Was ist der Ist-Zustand
                ( „Achtsamkeit statt Autopilot“)? Was sind positive
                Zukunftsbilder?
              </p>
              <br />
              <p>
                <b>Schritt 2: Ermutigung:</b> Durch Wissensvermittlung aus
                aktuellen Erkenntnissen aus Psychologie und Hirnforschung und
                auf Basis der Erfahrungen unserer langjährigen Tätigkeit in
                Wirtschaftsunternehmen und Non-Profit-Organisationen.
              </p>
              <br />
              <p>
                <b>Schritt 3: Verwirklichung/Umsetzung:</b> Konkretes
                Handwerkszeug für den nächsten Schritt.
              </p>
            </div>
          </li>
          <li>
            <div className="img">
              <GatsbyImage alt="test" image={ziel} objectFit="contain" />
            </div>
            <div>
              <h3>Unsere Arbeitsweise</h3>
              <p>
                Das Fundament unserer Arbeit basiert auf den Erkenntnissen der
                modernen Hirnforschung, Grundsätzen der Psychologie und der
                Essenz traditioneller Weisheitslehren. Besonders inspiriert sind
                wir von der Idee der Achtsamkeit wie sie beispielsweise der
                buddhistische Mönch und ZEN-Lehrer Thich Nhat Hanh oder
                Professor Jon Kabbat Zin vertreten.
              </p>
              <br />
              <p>
                <b>Achtsamkeit:</b> Es geht darum zu verändern WIE wir etwas
                erleben, nicht WAS wir erleben!
              </p>
              <p>
                <b>Pragmatismus:</b> Je ungewisser die Zukunft, umso wichtiger
                ist eine gute Orientierung, eine transparente Strategie und
                gutes Handwerkszeug für den Alltag!
              </p>
            </div>
          </li>
        </ul>
      </Styled.Intro>
      <Styled.Werte>
        <h3>Unsere Werte</h3>
        <ul>
          <li>
            <p>Transparenz und auf Augenhöhe</p>
            <p>
              Das bedeutet für uns, unsere Vorgehensweise von der
              Auftragsklärung bis zur Transferüberprüfung mit Ihnen abzustimmen.
            </p>
          </li>
          <li>
            <p>Respekt und Wertschätzung</p>
            <p>
              Das bedeutet für uns, Menschen und Veränderungsprozesse in ihrer
              Einzigartigkeit wahrzunehmen und uns auf Stärken und Ressourcen zu
              konzentrieren.
            </p>
          </li>
          <li>
            <p>
              Balance zwischen <br />
              Bewahren und Verändern
            </p>
            <p>
              Mit erfahrungsorientierten Methoden begleiten wir dabei, Bewährtes
              zu würdigen/stärken und neue Wahrnehmungs- und Handlungsspielräume
              zu entdecken und umzusetzen.
            </p>
          </li>
        </ul>
      </Styled.Werte>
      <Recommandation
        title={"Informieren Sie sich über unsere Leistungen"}
        data={{
          coaching: true,
          organisationsentwicklung: true,
          outdoor: true,
          seminare_und_workshops: true,
        }}
      />
    </Styled.Container>
  )
}

export default Grundhaltung
