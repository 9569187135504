import React from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import Button from "../button"

import * as Styled from "./ImgLink.styles"

interface Props {
  href: string
  alt: string
  image: ImageDataLike
  buttonText: string
}

const ImgLink = ({ href, alt, buttonText, image }: Props) => {
  const img: any = getImage(image)
  return (
    <Styled.Container>
      <Styled.ImgContainer>
        <GatsbyImage image={img} alt={alt} objectFit="contain" />
      </Styled.ImgContainer>
      <Button href={href} type="big">
        {buttonText}
      </Button>
    </Styled.Container>
  )
}

export default ImgLink
